/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.pokerchip {
  margin: 1em;
  position: relative;
  display: inline-block;
  width: 151px;
  height: 151px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5), 0 0 3px 0 rgba(0, 0, 0, 0.4) inset;
  border-radius: 50%;
  background-size: 151px 151px;
  background-position: center center;
}
.pokerchip:before {
  position: absolute;
  content: "";
  z-index: 1;
  width: 117px;
  height: 117px;
  border-radius: 50%;
  top: 18px;
  left: 18px;
  background-size: 151px 151px;
  background-position: center center;
}
.pokerchip:after {
  z-index: 2;
  position: absolute;
  content: " ";
  text-align: center;
  font: bold 50px/111px Arial;
  white-space: pre;
  width: 111px;
  height: 111px;
  border-radius: 50%;
  top: 20px;
  left: 20px;
  text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.3),
    1px 1px 0px rgba(255, 255, 255, 0.2);
}

/* Colors */
.white {
  background-image: linear-gradient(
      0deg,
      transparent 0,
      transparent 67.5px,
      #fff 67.5px,
      #fff 83.5px,
      transparent 83.5px,
      transparent 151px
    ),
    linear-gradient(
      60deg,
      transparent 0,
      transparent 97.4304px,
      #fff 97.4304px,
      #fff 113.4304px,
      transparent 113.4304px,
      transparent 151px
    ),
    linear-gradient(
      120deg,
      #dddddd 0,
      #dddddd 97.4304px,
      #fff 97.4304px,
      #fff 113.4304px,
      #dddddd 113.4304px,
      #dddddd 151px
    );
}
.white:before {
  border: 8px solid #dddddd;
  background-image: linear-gradient(
      0deg,
      transparent 0,
      transparent 69.5px,
      #ebebeb 69.5px,
      #ebebeb 81.5px,
      transparent 81.5px,
      transparent 151px
    ),
    linear-gradient(
      30deg,
      transparent 0,
      transparent 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      transparent 110.7104px,
      transparent 151px
    ),
    linear-gradient(
      60deg,
      transparent 0,
      transparent 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      transparent 110.7104px,
      transparent 151px
    ),
    linear-gradient(
      90deg,
      transparent 0,
      transparent 69.5px,
      #ebebeb 69.5px,
      #ebebeb 81.5px,
      transparent 81.5px,
      transparent 151px
    ),
    linear-gradient(
      120deg,
      transparent 0,
      transparent 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      transparent 110.7104px,
      transparent 151px
    ),
    linear-gradient(
      150deg,
      #c1c1c1 0,
      #c1c1c1 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      #c1c1c1 110.7104px,
      #c1c1c1 151px
    );
}
.white:after {
  background: #dddddd;
  color: #c1c1c1;
}
.red {
  background-image: linear-gradient(
      0deg,
      transparent 0,
      transparent 67.5px,
      #fff 67.5px,
      #fff 83.5px,
      transparent 83.5px,
      transparent 151px
    ),
    linear-gradient(
      60deg,
      transparent 0,
      transparent 97.4304px,
      #fff 97.4304px,
      #fff 113.4304px,
      transparent 113.4304px,
      transparent 151px
    ),
    linear-gradient(
      120deg,
      #800000 0,
      #800000 97.4304px,
      #fff 97.4304px,
      #fff 113.4304px,
      #800000 113.4304px,
      #800000 151px
    );
}
.red:before {
  border: 8px solid #800000;
  background-image: linear-gradient(
      0deg,
      transparent 0,
      transparent 69.5px,
      #ebebeb 69.5px,
      #ebebeb 81.5px,
      transparent 81.5px,
      transparent 151px
    ),
    linear-gradient(
      30deg,
      transparent 0,
      transparent 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      transparent 110.7104px,
      transparent 151px
    ),
    linear-gradient(
      60deg,
      transparent 0,
      transparent 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      transparent 110.7104px,
      transparent 151px
    ),
    linear-gradient(
      90deg,
      transparent 0,
      transparent 69.5px,
      #ebebeb 69.5px,
      #ebebeb 81.5px,
      transparent 81.5px,
      transparent 151px
    ),
    linear-gradient(
      120deg,
      transparent 0,
      transparent 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      transparent 110.7104px,
      transparent 151px
    ),
    linear-gradient(
      150deg,
      #6c0000 0,
      #6c0000 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      #6c0000 110.7104px,
      #6c0000 151px
    );
}
.red:after {
  background: #800000;
  color: #6c0000;
}
.blue {
  background-image: linear-gradient(
      0deg,
      transparent 0,
      transparent 67.5px,
      #fff 67.5px,
      #fff 83.5px,
      transparent 83.5px,
      transparent 151px
    ),
    linear-gradient(
      60deg,
      transparent 0,
      transparent 97.4304px,
      #fff 97.4304px,
      #fff 113.4304px,
      transparent 113.4304px,
      transparent 151px
    ),
    linear-gradient(
      120deg,
      #000099 0,
      #000099 97.4304px,
      #fff 97.4304px,
      #fff 113.4304px,
      #000099 113.4304px,
      #000099 151px
    );
}
.blue:before {
  border: 8px solid #000099;
  background-image: linear-gradient(
      0deg,
      transparent 0,
      transparent 69.5px,
      #ebebeb 69.5px,
      #ebebeb 81.5px,
      transparent 81.5px,
      transparent 151px
    ),
    linear-gradient(
      30deg,
      transparent 0,
      transparent 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      transparent 110.7104px,
      transparent 151px
    ),
    linear-gradient(
      60deg,
      transparent 0,
      transparent 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      transparent 110.7104px,
      transparent 151px
    ),
    linear-gradient(
      90deg,
      transparent 0,
      transparent 69.5px,
      #ebebeb 69.5px,
      #ebebeb 81.5px,
      transparent 81.5px,
      transparent 151px
    ),
    linear-gradient(
      120deg,
      transparent 0,
      transparent 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      transparent 110.7104px,
      transparent 151px
    ),
    linear-gradient(
      150deg,
      #00016c 0,
      #00016c 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      #00016c 110.7104px,
      #00016c 151px
    );
}
.blue:after {
  background: #000099;
  color: #00016c;
}
.green {
  background-image: linear-gradient(
      0deg,
      transparent 0,
      transparent 67.5px,
      #fff 67.5px,
      #fff 83.5px,
      transparent 83.5px,
      transparent 151px
    ),
    linear-gradient(
      60deg,
      transparent 0,
      transparent 97.4304px,
      #fff 97.4304px,
      #fff 113.4304px,
      transparent 113.4304px,
      transparent 151px
    ),
    linear-gradient(
      120deg,
      #008000 0,
      #008000 97.4304px,
      #fff 97.4304px,
      #fff 113.4304px,
      #008000 113.4304px,
      #008000 151px
    );
}
.green:before {
  border: 8px solid #008000;
  background-image: linear-gradient(
      0deg,
      transparent 0,
      transparent 69.5px,
      #ebebeb 69.5px,
      #ebebeb 81.5px,
      transparent 81.5px,
      transparent 151px
    ),
    linear-gradient(
      30deg,
      transparent 0,
      transparent 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      transparent 110.7104px,
      transparent 151px
    ),
    linear-gradient(
      60deg,
      transparent 0,
      transparent 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      transparent 110.7104px,
      transparent 151px
    ),
    linear-gradient(
      90deg,
      transparent 0,
      transparent 69.5px,
      #ebebeb 69.5px,
      #ebebeb 81.5px,
      transparent 81.5px,
      transparent 151px
    ),
    linear-gradient(
      120deg,
      transparent 0,
      transparent 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      transparent 110.7104px,
      transparent 151px
    ),
    linear-gradient(
      150deg,
      #016c00 0,
      #016c00 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      #016c00 110.7104px,
      #016c00 151px
    );
}
.green:after {
  background: #008000;
  color: #016c00;
}
.black {
  background-image: linear-gradient(
      0deg,
      transparent 0,
      transparent 67.5px,
      #fff 67.5px,
      #fff 83.5px,
      transparent 83.5px,
      transparent 151px
    ),
    linear-gradient(
      60deg,
      transparent 0,
      transparent 97.4304px,
      #fff 97.4304px,
      #fff 113.4304px,
      transparent 113.4304px,
      transparent 151px
    ),
    linear-gradient(
      120deg,
      #222222 0,
      #222222 97.4304px,
      #fff 97.4304px,
      #fff 113.4304px,
      #222222 113.4304px,
      #222222 151px
    );
}
.black:before {
  border: 8px solid #222222;
  background-image: linear-gradient(
      0deg,
      transparent 0,
      transparent 69.5px,
      #ebebeb 69.5px,
      #ebebeb 81.5px,
      transparent 81.5px,
      transparent 151px
    ),
    linear-gradient(
      30deg,
      transparent 0,
      transparent 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      transparent 110.7104px,
      transparent 151px
    ),
    linear-gradient(
      60deg,
      transparent 0,
      transparent 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      transparent 110.7104px,
      transparent 151px
    ),
    linear-gradient(
      90deg,
      transparent 0,
      transparent 69.5px,
      #ebebeb 69.5px,
      #ebebeb 81.5px,
      transparent 81.5px,
      transparent 151px
    ),
    linear-gradient(
      120deg,
      transparent 0,
      transparent 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      transparent 110.7104px,
      transparent 151px
    ),
    linear-gradient(
      150deg,
      #000000 0,
      #000000 98.7104px,
      #ebebeb 98.7104px,
      #ebebeb 110.7104px,
      #000000 110.7104px,
      #000000 151px
    );
}
.black:after {
  background: #222222;
  color: #000000;
}

.content-after-Y::after {
  content: "Y";
}
.content-after-N::after {
  content: "N";
}

.content-after-0::after {
  content: "0";
}
.content-after-1::after {
  content: "1";
}
.content-after-2::after {
  content: "2";
}
.content-after-3::after {
  content: "3";
}
.content-after-4::after {
  content: "4";
}
.content-after-5::after {
  content: "5";
}
.content-after-6::after {
  content: "6";
}
.content-after-7::after {
  content: "7";
}
.content-after-8::after {
  content: "8";
}
.content-after-9::after {
  content: "9";
}
.content-after-10::after {
  content: "10";
}
